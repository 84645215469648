@import "/src/Theme/custom.scss";

$gold           : #FFC01E;
$silver         : #A0A0A0;
$diamond        : #57ADDD;
$platinum       : #312D38;
$bronze          :#D69555;

.navbar-brand{
    display: inline-flex;
    padding: 0;
    margin: 0;
}
.avatars {
    width: auto;
    max-width: 100%;
    position: relative;
    img{
        object-fit: contain;
        width: auto;
        max-width: 100%;
        height: auto;
        display: inline-block;
    }
    .logo-login{
        width: 7.875rem;
        height: 7.875rem;
    }
    .profile-image {
        width: 126px;
        height: 126px;
        border-radius: 120px;
        transition: all 0.3s ease;
        display: block;
        object-fit: cover;
    }
    .header-pro-thumb{
        width: 58px;
        height: 58px;
        border-radius: 58px;
        transition: all 0.3s ease;
        display: block;
        object-fit: cover;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1)) drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.5));
        @media only screen and (max-width: $md-screen) {
            width: 46px;
            height: 46px;
        }
    }
    div{
        position: relative;
        &[membership="gold"]{
            img.header-pro-thumb{
                border: 2px solid $gold;
            }
            &::after{
                content: '';
                width: 26px;
                height: 28px;
                display: block;
                background-image: url("../../../public/images/badge-gold-sm.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: -10px;
                top: -1px;
                @media only screen and (max-width: $xs-screen) {
                    top: -5px;
                }
            }
        }
        &[membership="silver"]{
            img.header-pro-thumb{
                border: 2px solid $silver;
            }
            &::after{
                content: '';
                width: 28px;
                height: 29px;
                display: block;
                background-image: url("../../../public/images/badge-silver-sm.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: -10px;
                top: -1px;
            }
        }
        &[membership="platinum"]{
            img.header-pro-thumb{
                border: 2px solid $platinum;
            }
            &::after{
                content: '';
                width: 27px;
                height: 29px;
                display: block;
                background-image: url("../../../public/images/badge-platinum-sm.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: -10px;
                top: -1px;
            }
        }
        &[membership="diamond"]{
            img.header-pro-thumb{
                border: 2px solid $diamond;
            }
            &::after{
                content: '';
                width: 27px;
                height: 29px;
                display: block;
                background-image: url("../../../public/images/badge-diamond-sm.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: -10px;
                top: -1px;
            }
        }
        &[membership="bronze"]{
            img.header-pro-thumb{
                border: 2px solid $bronze;
            }
            &::after{
                content: '';
                width: 27px;
                height: 29px;
                display: block;
                background-image: url("../../../public/images/badge-bronze-sm.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: -10px;
                top: -1px;
            }
        }
        &.profile-wrp{
            &[membership]{
                width: 146px;
                height: 146px;
                border-radius: 150px;
                background-color: $secondary-color;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                box-shadow: inset -3px -3px 4px $white, inset 1px 1px 4px  rgba($color: $text-gray03, $alpha: 0.5);
                &::before{
                    content: '';
                    width: 138px;
                    height: 138px;
                    border: 4px solid $info-color;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border-radius: 140px;
                    margin: auto;
                }
                &::after{
                    bottom: 0;
                    right: 1rem;
                    top: unset;
                    width: 34px;
                    height: 34px;
                }
                &[membership="gold"]{
                    &::before{
                        border-color: $gold;
                    }
                }
                &[membership="bronze"]{
                    &::before{
                        border-color: $bronze;
                    }
                }
                &[membership="silver"]{
                    &::before{
                        border-color: $silver;
                    }
                }
                &[membership="diamond"]{
                    &::before{
                        border-color: $diamond;
                    }
                }
                &[membership="platinum"]{
                    &::before{
                        border-color: $platinum;
                    }
                }
                @media only screen and (max-width: $lg-screen) {
                    width: 100px;
                    height: 100px;
                    .profile-image{
                        width: 84px;
                        height: 84px;
                    }
                    &::before{
                        width: 94px;
                        height: 94px;
                        border: 3px solid $info-color;
                    }
                    &::after{
                        right: 0.25rem;
                    }
                }
            }
        }
    }
    .product-item-image{
        height: 173px;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: inset -3px -3px 13px #FFFFFF, inset 3px 3px 6px rgba(97, 97, 97, 0.4);
        border: none;
        padding: 3px;
        cursor: pointer;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
        }
        .product-code{
            background-color: $secondary-color;
            border-radius: 4px;
            padding: 0.25rem 0.5rem;
            font-size: 0.75rem;
            line-height: 1.375rem;
            font-weight: $font-semibold;
        }
    }
    .product-detail-image{
        height: 268px;
        overflow: hidden;
        border: none;
        border-radius: 8px;
        @media only screen and (min-width: $hd-screen) {
            height: 330px;
        }
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
        }
    }
    .product-detail-carousal-options{
        height: 100%;
        width: 100%;
        overflow: hidden;
        border: none;
        border-radius: 6px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 6px;
        }
    }
    .gif-loader{
        width: 500px;
        max-width: 100%;
        object-fit: contain;
        mix-blend-mode: darken;
    }
    

    // Cart Image
    .cart-list-image{
        width: 150px;
        height: 100px;
        cursor: pointer;
        @media only screen and (max-width: $sm-screen) {
            width: 100px;
            height: 70px;
        }
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
            margin: 0;
            box-shadow: 0px 0px 0px 1px $border-light;
        }
    }
    .row-image{
        width: 3.25rem;
        height: 3.25rem;
        cursor: pointer;
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: center;
            border-radius: 8px;
            margin: 0;
        }
    }

    .story-image{
        img{
            width: 100%;
            height: auto;
            max-height: 80vh;
            object-fit: contain;
            object-position: center;
        }
    }

    .order-list-image{
        width: 120px;
        height: 82px;
        cursor: pointer;
        @media only screen and (max-width: $sm-screen) {
            width: 102px;
            height: 70px;
        }
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
            margin: 0;
        }
        &.notify{
            &::after{
                content: '';
                display: block;
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 0.75rem;
                // background-color: $primary-color;
                position: absolute;
                right: -0.25rem;
                top: -0.125rem;
            }
        }
    }
}
.avatar-loader-small{
    .gif-loader{
        width: 350px;
    }
}