// @use "/src/Theme/custom.scss" as theme;
@import "/src/Theme/custom.scss";

$gold-button      : #9F8140;
$silver-button    : #949393;
$diamond-button  : #5E93B1;
$platinum-button  : #ADABAF;
$bronze-button    :#D69555;

@mixin gold-linear {
    background: rgb(143,130,78);
    background: -moz-linear-gradient(135deg, rgba(143,130,78,1) 0%, rgba(174,138,72,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(143,130,78,1) 0%, rgba(174,138,72,1) 100%);
    background: linear-gradient(135deg, rgba(143,130,78,1) 0%, rgba(174,138,72,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8f824e",endColorstr="#ae8a48",GradientType=1);
}
@mixin gold-button-linear{
    background: rgb(162,133,71);
    background: -moz-linear-gradient(135deg, rgba(162,133,71,1) 0%, rgba(150,123,64,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(162,133,71,1) 0%, rgba(150,123,64,1) 100%);
    background: linear-gradient(135deg, rgba(162,133,71,1) 0%, rgba(150,123,64,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a28547",endColorstr="#967b40",GradientType=1);
}

@mixin silver-linear {
    background: rgb(155,154,154);
    background: -moz-linear-gradient(135deg, rgba(155,154,154,1) 0%, rgba(125,124,124,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(155,154,154,1) 0%, rgba(125,124,124,1) 100%);
    background: linear-gradient(135deg, rgba(155,154,154,1) 0%, rgba(125,124,124,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9b9a9a",endColorstr="#7d7c7c",GradientType=1);
}
@mixin silver-button-linear{
    background: rgb(148,147,147);
    background: -moz-linear-gradient(135deg, rgba(148,147,147,1) 0%, rgba(135,134,134,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(148,147,147,1) 0%, rgba(135,134,134,1) 100%);
    background: linear-gradient(135deg, rgba(148,147,147,1) 0%, rgba(135,134,134,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#949393",endColorstr="#878686",GradientType=1);
}

@mixin diamond-linear {
    background: rgb(110,160,188);
    background: -moz-linear-gradient(135deg, rgba(110,160,188,1) 0%, rgba(49,112,147,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(110,160,188,1) 0%, rgba(49,112,147,1) 100%);
    background: linear-gradient(135deg, rgba(110,160,188,1) 0%, rgba(49,112,147,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6ea0bc",endColorstr="#317093",GradientType=1);
}
@mixin diamond-button-linear{
    background: rgb(94,147,177);
    background: -moz-linear-gradient(135deg, rgba(94,147,177,1) 0%, rgba(76,125,153,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(94,147,177,1) 0%, rgba(76,125,153,1) 100%);
    background: linear-gradient(135deg, rgba(94,147,177,1) 0%, rgba(76,125,153,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5e93b1",endColorstr="#4c7d99",GradientType=1);
}

@mixin platinum-linear {
    background: rgb(49,45,56);
    background: -moz-linear-gradient(120.88deg, rgba(173,171,175,1) 0%, rgba(49,45,56,1) 100%);
    background: -webkit-linear-gradient(120.88deg, rgba(173,171,175,1) 0%, rgba(49,45,56,1) 100%);
    background: linear-gradient(120.88deg, rgba(173,171,175,1) 0%, rgba(49,45,56,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ADABAF",endColorstr="#312D38",GradientType=1);
}
@mixin platinum-button-linear{
    background: rgb(173,171,175);
    background: -moz-linear-gradient(314.71deg, rgba(0, 0, 0, 0.03) 8.64%, rgba(0, 0, 0, 3e-06) 89.83%);
    background: -webkit-linear-gradient(314.71deg, rgba(0, 0, 0, 0.03) 8.64%, rgba(0, 0, 0, 3e-06) 89.83%);
    background: linear-gradient(314.71deg, rgba(0, 0, 0, 0.03) 8.64%, rgba(0, 0, 0, 3e-06) 89.83%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ADABAF",endColorstr="#312D38",GradientType=1);

    
}
@mixin bronze-button-linear {
    background: rgb(214, 149, 85);
    background: -moz-linear-gradient(135deg, rgba(214, 149, 85, 1) 0%, rgba(214, 149, 85, 1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(214, 149, 85, 1) 0%, rgba(214, 149, 85, 1) 100%);
    background: linear-gradient(135deg, rgba(214, 149, 85, 1) 0%, rgba(214, 149, 85, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#D69555", endColorstr="#D69555", GradientType=1);
}

@mixin bronze-linear {
    background: rgb(214, 149, 85);
    background: -moz-linear-gradient(135deg, rgba(214, 149, 85, 1) 0%, rgba(214, 149, 85, 1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(214, 149, 85, 1) 0%, rgba(214, 149, 85, 1) 100%);
    background: linear-gradient(135deg, rgba(214, 149, 85, 1) 0%, rgba(214, 149, 85, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#D69555", endColorstr="#D69555", GradientType=1);
}


.profile-head{
    width: 100%;
    margin-bottom: 1.5rem;
    flex-grow: 0;
    @media only screen and (max-width: $sm-screen) {
        margin-bottom: 1rem;
    }
    .cards{
        .profile-head-card{
            background-color: transparent;
            border-radius: 0;
            border: none;
            .card-body{
                padding: 0;
                .profile-head-type{
                    background-color: $info-color;
                    padding: 1rem;
                    border-radius: 1rem;
                    padding: 1.875rem;
                    @media only screen and (max-width: $sm-screen) {
                        padding: 1rem;
                    }
                    .profile-back{
                        position: absolute;
                        @media only screen and (max-width: $xl-screen) {
                            position: relative;
                        }
                    }
                    .btn-button-wishlist,.btn-button-back{
                        border: 1px solid rgba(255,255,255,0.20);
                    }
                    &.gold{
                        @include gold-linear;
                        .btn-button-positive{
                            @include gold-button-linear;
                        }
                        .btn-button-wishlist,.btn-button-back{
                            background-color: $gold-button;
                            svg{
                                *{fill: $gold-button;}
                            }
                        }
                    }
                    &.silver{
                        @include silver-linear;
                        .btn-button-positive{
                            @include silver-button-linear;
                        }
                        .btn-button-wishlist,.btn-button-back{
                            background-color: $silver-button;
                            svg{
                                *{fill: $silver-button;}
                            }
                        }
                    }
                    &.platinum{
                        @include platinum-linear;
                        .btn-button-positive{
                            @include platinum-button-linear;
                        }
                        .btn-button-wishlist,.btn-button-back{
                            background-color: $platinum-button;
                            svg{
                                *{fill: $platinum-button;}
                            }
                        }
                    }
                    &.diamond{
                        @include diamond-linear;
                        .btn-button-positive{
                            @include diamond-button-linear;
                        }
                        .btn-button-wishlist,.btn-button-back{
                            background-color: $diamond-button;
                            svg{
                                *{fill: $diamond-button;}
                            }
                        }
                    }
                    &.bronze{
                        @include bronze-linear;
                        .btn-button-positive{
                            @include bronze-button-linear;
                        }
                        .btn-button-wishlist,.btn-button-back{
                            background-color: $bronze-button;
                            svg{
                                *{fill: $bronze-button;}
                            }
                        }
                    }
                }
            }
        }
    }
}

.settings{
    .profile-head{
        margin-bottom: 0;
        .cards{
            .profile-head-card{
                .card-body{
                    .profile-head-type{
                        border-radius: 1rem 1rem 0 0;
                    }
                }
            }
        }
    }
    .tabs{
        .nav-tabs{
            margin-bottom: 1.75rem;
            background-color: $white;
            padding: 1rem;
            justify-content: center;
            align-items: center;
            border-radius: 0 0 1rem 1rem;
            .nav-item{
                margin: 0.5rem 1.25rem;
                .nav-link{
                    padding: 0.25rem 0;
                }
            }
        }
    }
}