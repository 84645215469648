@import "/src/Theme/custom.scss";

.chatWidget{
    min-width: 28.75rem;
    max-width: 28.75rem;
    background-color: $white;
    border-radius: 1rem;
    &.offcanvas-bottom{
        border: 1px solid $border-light;
        left: unset;
        right: 1.875rem;
        height: 38.75rem;
        max-height: calc(100vh - 4rem);
        z-index: 9999;
        @media only screen and (max-width: $xs-screen) {
            right: 0;
        }
        &.show{
            transform: translateY(-1.875rem);
            @media only screen and (max-width: $xs-screen) {
                transform: none;
            }
        }
        .offcanvas-body{
            .chat-header{
                max-width: calc(100% - 90px);
            }
            .avatars{
                .header-pro-thumb{
                    width: 2.5rem;
                    height: 2.5rem;
                    min-width: 2.5rem;
                }
            }
            .header-pro-title{
                max-width: calc(100% - 2.5rem);
            }
            hr{
                background-color: $text-gray03;
            }
            .chat-loading{
                .avatars {
                    img {
                        height: 150px;
                        width: 150px;
                    }
                }
            }
            .chat-body{
                height: 27rem;
                overflow: hidden;
                overflow-y: auto;
                display: flex;
                flex-flow: column;
                flex-grow: 1;
                .chat-each{
                    margin-bottom: 1rem;
                    .cards{
                        max-width: calc(100% - 5rem);
                        @media only screen and (max-width: $xs-screen) {
                            max-width: calc(100% - 4rem);
                        }
                        .card{
                            background-color: $secondary-color;
                            border: 1px solid $light-border;
                            border-radius: 0 1.125rem 1.125rem;
                        }
                    }
                    .avatars{
                        img{
                            &.header-pro-thumb{
                                width: 1.625rem;
                                height: 1.625rem;
                                min-width: 1.625rem;
                                filter: none;
                            }
                        }
                    }
                    &.chat-me{
                        .cards{
                            .card{
                                border-radius: 1.125rem 0 1.125rem 1.125rem;
                                background-color: $secondary-color;
                                background: linear-gradient(314.71deg, rgba(0, 0, 0, 2e-06) 8.64%, rgba(0, 0, 0, 0.02) 89.83%), #F0F0F0;
                                border: 3px solid $border-light;
                                box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15), -2px -2px 4px rgba(255, 255, 255, 0.36), inset 2px 2px 4px 1px rgba(0, 0, 0, 0.09), inset -2px -1px 6px 1.5px rgba(255, 255, 255, 0.82);
                                .card-body{
                                    padding: 0.5rem 1rem;
                                }
                            }
                        }
                    }
                }
            }
            .chat-form-box{
                position: relative;
                .form-control.neu-form, .form-control.neu-form:focus{
                    min-height: 2.625rem;
                    border-radius: 0.5rem;
                    padding-left: 2.5rem;
                }
                .btn-smiley{
                    position: absolute;
                    left: 14px;
                    top: 0.7rem;
                }
            }
        }
        @media only screen and (max-width: $xs-screen) {
            max-width: 100vw;
            width: 100vw;
            border-radius: 0;
            border: 0;
            height: 100vh;
            max-height: 100vh;
        }
    }
}