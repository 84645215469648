// Colors
$primary-color  : #EC2B2F;
$secondary-color: #F1F1F1;
$danger-color   : #EC2B2F;
$info-color     : #F27174;
$info-active    : #f05a5d;
$info-light     : #FFCECF;
$dark01         : #212121;
$dark02         : #262626;
$text-gray      : #818181;
$text-gray02    : #959EAE;
$text-gray03    : #616161;
$black          : #000000;
$white          : #ffffff;
$gold           : #FFC01E;
$silver         : #A0A0A0;
$platinum       : #57ADDD;
$bronze         : #D69555;
$success-color  : #00B16A;
$light-border   : #FAFAFA;
$border-light   : #EAE5E0;
$light-gray     : #E1E1E1;
$gray-bg        : #ECECEC;
$border-gray    : #D7D7D7;
$border-medium  : #B9B9B9;
$rose-color     : #F178B6;
$blue-color     : #5D61CD;
$sky-blue-color : #7AD3E8;
$majenta-color  : #9C4FCC;
$gray-border    : #E5E5E5;
$form-disabled  : #F5F5F5;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "info": $info-color,
    "danger": $danger-color,
    "success": $success-color,
    "dark": $dark01,
    "black": $black,
    "white": $white
);

// Font Weight
$font-light          : 300;
$font-regular        : 400;
$font-medium         : 500;
$font-semibold       : 600;
$font-bold           : 700;
$font-extrabold      : 800;



// Responsive Screens
$hd-screen           : 1700px;
$xl-screen           : 1399.98px;
$lg-screen           : 1199.98px;
$md-screen           : 991.98px;
$sm-screen           : 767.98px;
$min-sm-screen       : 768px;
$xs-screen           : 575.98px;
$min-md-screen       : 992px;
$min-lg-screen       : 1200px;
$max-xs-screen       : 575px;


$drawer-width           : 270px;
$drawer-md-width        : 220px;
$drawer-collapsed-width : 90px;