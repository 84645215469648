@import "/src/Theme/custom.scss";

.news-and-offers {
    width: 100%;
    &.flex-fill-column {
        height: 50%;
        flex-grow: 1;
        overflow: hidden;
        overflow-y: auto;
    }
    .items-list {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .container-negative {
        box-shadow: inset -3px -3px 13px $white, inset 3px 3px 6px rgba(97, 97, 97, 0.4);
        border-radius: 8px;
        background-color: $secondary-color;
    }
    .logo-image {
        margin: auto;
        text-align: center;
        cursor: pointer;
        
        
    }
    .news-quick-cards {
        padding: 1.875rem 1rem 1rem;
        height: 100%;
        border-radius: 8px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        background-color: $info-color;
        border: 1px solid $light-border;
        box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(0, 0, 0, 0.15);
        @media only screen and (max-width: $md-screen) {
            padding: 1rem;
        }
        &.news-quick-item01 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ec2b2f+0,f7a2a4+100 */
            background: -moz-linear-gradient(45deg, #ec2b2f 0%, #f7a2a4 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(45deg, #ec2b2f 0%, #f7a2a4 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                45deg,
                #ec2b2f 0%,
                #f7a2a4 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec2b2f', endColorstr='#f7a2a4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
        &.news-quick-item02 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#be8533+0,eee4ba+100 */
            background: -moz-linear-gradient(45deg, #be8533 0%, #eee4ba 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(45deg, #be8533 0%, #eee4ba 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                45deg,
                #be8533 0%,
                #eee4ba 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#be8533', endColorstr='#eee4ba',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
        &.news-quick-item03 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#387fa7+0,a5dfff+100 */
            background: -moz-linear-gradient(45deg, #387fa7 0%, #a5dfff 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(45deg, #387fa7 0%, #a5dfff 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                45deg,
                #387fa7 0%,
                #a5dfff 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#387fa7', endColorstr='#a5dfff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
        &.news-quick-item04 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#9c4fcc+0,eacdfc+100 */
            background: -moz-linear-gradient(45deg, #9c4fcc 0%, #eacdfc 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(45deg, #9c4fcc 0%, #eacdfc 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                45deg,
                #9c4fcc 0%,
                #eacdfc 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9c4fcc', endColorstr='#eacdfc',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
        .opacity-90 {
            opacity: 0.9;
        }
    }
    .custom-loader {
        width: auto;
        .large {
            .placeholder {
                width: 100%;
                height: 13.75rem;
                overflow: hidden;
                border-radius: 8px;
            }
        }
        .medium {
            .placeholder {
                width: 100%;
                height: 11.438rem;
                overflow: hidden;
                border-radius: 8px;
            }
        }
    }
    .news-and-offers-large {
        .slick-slider {
            .slick-list {
                .slick-track {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

